/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .js-touch-focused, .js-touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".js-touch-focused").not($o).not($o.closest(".js-touch-focused")).removeClass("focused");
            $o.addClass("focused");
        } else {
            if ($o.hasClass('js-touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .js-touch-focused a, .js-touch-focused-all a", function (e) {
        var $tf = $(this).closest(".js-touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".js-touch-focused").length) {
            $(".js-touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".js-prevent-default", function (e) {
        e.preventDefault();
    });

    $(document).on("submit", ".js-form-prevent-default", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".js-stop-propagation", function (e) {
        e.stopPropagation();
    });

}


/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function () {
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function (value) {
                return parseInt(value);
            }).sort(function (a, b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: " + breakpoints[i] + "px)").matches) {
                    if ($(places[breakpoints[i]]).length) {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("body").trigger("responsive-update");

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}


/*

Scroll Lazy Loading

 */

function blazy_init() {

    $('.js-blazy-revalidate-on-scroll').on('scroll', function () {
        blazy_revalidate_on_scroll_delay(function () {
            if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                Blazy.revalidate();
            }
        }, 50);
    });

    window.Blazy = new Blazy({
        offset: 0,
        success: function (ele) {
            var $o = $(ele).closest(".img-to-bg");
            img_to_bg($o);
            $o.addClass('img-to-bg-lazy-loaded');
            //console.log('Blazy', ele, 'success');
            blazy_callback(ele);
        }
        , error: function (ele, msg) {
            //console.log('Blazy', ele, msg);
            blazy_callback(ele);
            if (msg === 'missing') {
                //console.log(ele, msg);
            } else if (msg === 'invalid') {
                //console.log(ele, msg);
            }
        }
    });

    $(window).on('resize', function () {
        blazy_revalidate_delay(function () {
            blazy_revalidate();
        }, 100);
    });

    $(document).on('shown.bs.modal', function () {
        blazy_revalidate();
    });

    $('.js-slick-slider-revalidate-image').on('init reInit setPosition', function (event) {
        blazy_revalidate();
    });
}

function blazy_callback(ele) {
    var callback = $(ele).not('.blazy-callback-executed').data('src-callback');
    if (callback) {
        var callback_params = $(ele).data('src-callback-params');
        if (!callback_params) {
            callback_params = [];
        }
        var fn = getFunctionFromString(callback);
        if (typeof fn === "function") {
            $(ele).addClass('blazy-callback-executed');
            fn.apply(ele, callback_params);
        }
    }
}

function blazy_revalidate() {
    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
        Blazy.revalidate();
    }
}

var blazy_revalidate_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

var blazy_revalidate_on_scroll_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

// Get function from string, with or without scopes (by Nicolas Gauthier)
window.getFunctionFromString = function (string) {
    var scope = window;
    var scopeSplit = string.split('.');
    for (i = 0; i < scopeSplit.length - 1; i++) {
        scope = scope[scopeSplit[i]];

        if (scope == undefined) return;
    }

    return scope[scopeSplit[scopeSplit.length - 1]];
};


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited")) {

        $(window).on("resize", function () {
            delay_img_to_bg(function () {
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = "";
        if ($img.length) {
            var src = $img[0].currentSrc;
        }
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();


/*

Scroll to needed objects

 */

function goto_init() {

    if (location.hash && location.hash.length) {
        var $o = $(location.hash);
        goto_object($o, 0);
    }

    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#' + hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".goto-list").length) {
                    $this.closest(".goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".tabs"));
                }
                if ($this.closest(".goto-hash-change").length) {
                    location_hash_update($this.attr("href"));
                }
                goto_object($o);
            }
        }
    });
}

function goto_object($o, speed, doffset) {
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    if ($o.length) {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var $offset = $(".js-goto-offset").first();
        if ($offset.length) {
            offset -= $offset.outerHeight();
        }
        if ($o.data("goto-offset-element")) {
            offset -= $($o.data("goto-offset-element")).outerHeight();
        }
        goto_offset(offset, speed, $o);
    }
}

function goto_offset(offset, speed, $o) {
    if (typeof speed === 'undefined') {
        speed = 700;
    }
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed, function () {
        $(this).trigger('goto-end');
        if ($o) {
            $o.trigger('goto-end');
        }
    });
}


/*

Actions on Scroll

 */

function scroll_animations() {
    body_scrolled();
}

function body_scrolled() {
    requestAnimationFrame(function () {
        var $o = $(".js-header-on-scroll");
        if ($o.length) {
            var st = $(window).scrollTop();
            var scrolled_condition = st > 0;
            var wh = window.innerHeight;
            if (scrolled_condition) {
                if (!$o.hasClass("scrolled")) $o.addClass("scrolled");
            } else {
                if ($o.hasClass("scrolled")) $o.removeClass("scrolled");
            }
            var scrolled_condition = st > wh;
            if (scrolled_condition) {
                if (!$o.hasClass("scrolled-fold")) $o.addClass("scrolled-fold");
            } else {
                if ($o.hasClass("scrolled-fold")) $o.removeClass("scrolled-fold");
            }
            scroll_back_header($o);
        }
    });
}

function scroll_back_header($o) {
    var y_offset = window.pageYOffset;
    this.menuScrolled = y_offset > 0;
    this.menuHidden = y_offset > $('.header-replace').height() && y_offset > this.oldScrollPosition;
    if (y_offset != this.oldScrollPosition) {
        $o.toggleClass("header--hidden", this.menuHidden);
        $o.toggleClass("header--scrolled", this.menuScrolled);
    }
    this.oldScrollPosition = y_offset;
}

function scroll_back_header_hide($o) {
    var y_offset = window.pageYOffset;
    this.menuHidden = y_offset > $('.header-replace').height();
    $o.toggleClass("header--hidden", this.menuHidden);
}


/*

Header Init

*/

function header_init() {

    $(document).on("click", ".js-menu-switcher", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu-overlay-active");
        if ($("html").hasClass("menu-overlay-active")) {
            bodyScrollLock.disableBodyScroll($('.menu-overlay__content')[0]);
        } else {
            bodyScrollLock.enableBodyScroll($('.menu-overlay__content')[0]);
        }
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        e.preventDefault();
        menu_overlay_hide();
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".menu-overlay, .js-menu-switcher").length) {
            if ($("html").hasClass("menu-overlay-active")) {
                menu_overlay_hide();
            }
        }
    });

    $(document).on("touchmove", ".menu-overlay", function (e) {
        e.stopPropagation();
    });

    $(window).on("scroll", function (e) {
        if ($("html").hasClass("menu-overlay-active")) {
            menu_overlay_hide();
        }
    });
}

function menu_overlay_hide() {
    $("html").removeClass("menu-overlay-active");
    bodyScrollLock.enableBodyScroll($('.menu-overlay__content')[0]);
}


/*

Expanded Blocks Functionality

 */

function expand_it_init() {
    //expand_it_init_prepare(null, true);
    window['expand_it_window_width'] = $(window).width();
    $(window).on("resize", function () {
        if ($(this).width() != window['expand_it_window_width']) {
            expand_it_init_prepare(null, true);
            window['expand_it_window_width'] = $(this).width();
        }
    });

    $(document).on("click expand-it", ".expand-it", function (e) {
        if (!$(this).data('no-prevent-default')) {
            e.preventDefault();
        }
        var $o = $($(this).data("expand-selector"));
        if (!$o.length) {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        }
        if (!$o.length) {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        expand_it_trigger($(this), $o, e.type === "expand-it");
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function (e) {
        var $o = $(e.target);
        if ($o.hasClass('expand-it-container')) {
            if ($o.hasClass("before-transition")) {
                expand_it_height_check($o);
            }
            expand_it_init_prepare($o.closest('.expand-it-container'), true);
            $(e.target).removeClass("before-transition");
            $(e.target).trigger("expandAfter");
        }
    });

    if (location.hash && location.hash.substr(0, 1).match(/\w/i)) {
        if ($(location.hash).filter(".expand-it-wrapper").length) {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='" + location.hash + "']");
            if (!$loc_link.length) {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length) {
                setTimeout(function () {
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }

    $(document).on("expand-it-open", function (e) {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
    });
}

function expand_it_height_check($o) {
    if ($o.hasClass("expand-it-container") && !$o.hasClass("expand-it-container-overflow-hidden")) {
        var height_default = 0;
        var current_height = parseInt($o.css("max-height"), 10);
        if ($o.find(".expand-it-container-height-default").length) {
            height_default = $o.find(".expand-it-container-height-default").height();
        }
        $o.toggleClass("overflow-visible", current_height > height_default);
    }
    if ($o.hasClass("expand-it-container") && $o.hasClass("expand-it-container-max-height-auto")) {
        var id = $o.attr("id");
        setTimeout(function () {
            removeCSSRule("rule-" + id);
        }, 300);
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id) {
        id = "id" + (new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    expand_it_init_prepare($o, true);
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("no-transition-only-this");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden")) {
        $o.removeClass("overflow-visible");
    }
    if ($o.hasClass("active")) {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("no-transition-only-this");
    if (!$o.hasClass("active") && !$("#rule-" + id).length) {
        addCSSRule("rule-" + id, "#" + id + ".active { max-height: " + height + "px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active");
    $(".expand-it.active[href='#" + $o.attr("id") + "']").not($handler).toggleClass("active");

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft) {
        $o.trigger("expandBefore");
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function () {
            $(".expand-it.active[href='#" + $handler.attr("id") + "']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings")) {
            $wrapper.siblings(".expand-it-wrapper").each(function () {
                var $this = $(this).find(".expand-it-container").first();
                if ($(this).find(".expand-it").length) {
                    $(this).find(".expand-it.active").trigger("expand-it");
                } else {
                    var $this_other = $(".expand-it.active[href='#" + $this.attr("id") + "']");
                    if ($this_other.length) {
                        $this_other.trigger("expand-it");
                    } else {
                        var $this_other = $(this).find('.expand-it-pseudo')/*.filter(function() {
                            return $(this).parent($this).length === 0;
                        })*/;
                        if ($this_other.length) {
                            expand_it_trigger($this_other, $this, true);
                        }
                    }
                    if ($this_other.length) {
                        $this_other.removeClass("active");
                    }
                }
                $this.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
        }
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings") || $wrapper.hasClass("expand-it-wrapper-scroll-to")) {
            if (!$wrapper.hasClass("expand-it-wrapper-collapse-siblings") || $wrapper.hasClass("active")) {
                setTimeout(function () {
                    var $sticky = $(".js-goto-offset-expand");
                    var offset = 0;
                    if ($sticky.length) {
                        $sticky.each(function () {
                            offset = Math.max(offset, $sticky.outerHeight() + parseInt($sticky.css('top'), 10));
                        });
                    }
                    if ($wrapper.offset().top < $(window).scrollTop() + offset) {
                        goto_object($wrapper, 400, -1 * offset - parseInt($wrapper.css('margin-top'), 10));
                    }
                }, 400);
            }
        }
        if (($o.hasClass("active") || $o.hasClass("expand-it-container--scroll-to-always")) && $handler.hasClass("expand-it-scroll-to")) {
            goto_object($o, 500, 'easeInOutQuad');
        }
        if ($handler.hasClass("expand-it-hash-change")) {
            if (is_active) {
                if ($handler.attr("href")) {
                    location_hash_update($handler.attr("href"));
                } else if ($wrapper.attr("id")) {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            } else {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id")) {
                    location_hash_update("#" + $tabpane.attr("id"));
                } else {
                    location_hash_remove();
                }
            }
        }
        $o.trigger('expand-it-' + ((is_active) ? 'open' : 'close'));
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    var rules = '';
    $c.each(function () {
        var $o = $(this);
        var id = $o.attr("id");
        if (!id) {
            id = "id" + (new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        //height = $o.find(".expand-it-inner").outerHeight(true);
        height = $o.find(".expand-it-inner")[0].clientHeight;
        rules += " #" + id + ".active { max-height: " + height + "px; } ";
        $o.addClass("expand-it-container-prepared");
    });
    if (rules) {
        addCSSRule("rule-expand-it", rules);
    }
}


/*

Hide/Show blocks

 */

function toggle_element_init() {
    $(document).on("click click-pseudo change toggle-hide", ".toggle-element, .show-element, .hide-element", function (e) {
        if ($(this).is("a")) e.preventDefault();
        if (e.type != "change" && $(this).is(":checkbox,:radio")) {
            return true;
        }
        var $o = $();
        if ($(this).attr("href") && $(this).attr("href").length > 1) {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) {
            $o = $($(this).data("selector"));
        }
        var restore_scroll = $(this).data("toggle-restore-scroll-position");
        if (restore_scroll) {
            var scroll_pos = $(window).scrollTop();
            var $scroll_relative = $(this);
            var scroll_relative = $(this).data('toggle-restore-scroll-relative');
            if (scroll_relative) {
                $scroll_relative = $(scroll_relative);
            }
            var offset_pos = parseInt($scroll_relative.offset().top, 10);
        }
        var class_name = "hidden";
        if ($(this).data("toggle-class")) {
            class_name = $(this).data("toggle-class");
        }
        var element_class_name = "active";
        if ($(this).data("toggle-element-class")) {
            element_class_name = $(this).data("toggle-element-class");
        }
        var change_label = false;
        if (($(this).hasClass("toggle-element") && e.type != "toggle-hide") || (e.type == "toggle-hide" && $o.hasClass(element_class_name))) {
            change_label = true;
            if ($(this).is(":checkbox,:radio")) {
                if (e.type == "change") {
                    $o.toggleClass(class_name, !$(this).prop("checked"));
                }
            } else {
                $(this).toggleClass(element_class_name);
                $o.toggleClass(class_name);
            }
            $o.data('toggle-class', class_name);
            $o.data('toggle-element-class', element_class_name);
            $o.data('toggle-element', $(this));
            $o.trigger("toggle-element");
        }
        if ($(this).data("toggle-close-other") && e.type != "toggle-hide") {
            var group = $(this).data("toggle-group");
            $('.toggle-element, .show-element').not(this).filter('[data-toggle-group="' + group + '"]').each(function () {
                $(this).trigger('toggle-hide');
            });
        }
        if ($(this).hasClass("show-element")) {
            $o_s = $($(this).data("selector-show"));
            if ($o_s.length) {
                $o = $o_s;
            }
            if (!$(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !!$(this).data("toggle-reverse"));
            $o.trigger("show-element");
        }
        if ($(this).hasClass("hide-element")) {
            $o_h = $($(this).data("selector-hide"));
            if ($o_h.length) {
                $o = $o_h;
            }
            if ($(this).hasClass(element_class_name)) change_label = true;
            $(this).addClass(element_class_name);
            $o.toggleClass(class_name, !$(this).data("toggle-reverse"));
            $o.trigger("hide-element");
        }
        if (change_label && $(this).data("toggle-change-label")) {
            var label = $(this).html();
            $(this).html($(this).attr("data-toggle-change-label"));
            $(this).attr("data-toggle-change-label", label);
        }
        var $siblings = $();
        if ($(this).data("toggle-siblings-selector")) {
            $siblings = $(this).closest($(this).data("toggle-siblings-selector")).siblings();
        }
        if ($(this).data("toggle-current-selector")) {
            $siblings = $siblings.find($(this).data("toggle-current-selector"));
        }
        if ($siblings.length) {
            $siblings.removeClass(element_class_name);
        }
        if (restore_scroll) {
            var scroll_pos_new = $(window).scrollTop();
            var offset_pos_new = parseInt($scroll_relative.offset().top, 10);
            $(window).scrollTop(offset_pos_new - (offset_pos - scroll_pos));
        }
    });

    $(document).on("click", "*", function (e) {
        toggle_element_close_on_blur_delay(function () {
            if (!$(e.target).closest(".toggle-element").length && !$(e.target).closest(".toggle-element-object").length) {
                $(".toggle-element-object[data-toggle-element-close-on-blur]").each(function () {
                    var classname = $(this).data('toggle-class');
                    if (!classname) classname = 'hidden';
                    $(this).removeClass(classname);
                    $(this).trigger("toggle-element");
                    if ($(this).data('toggle-element')) {
                        $($(this).data('toggle-element')).removeClass($(this).data('toggle-element-class'));
                    }
                });
            }
        }, 0);
    });
}

var toggle_element_close_on_blur_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();


function misc_init() {

    $(document).on('show-element', function () {
        responsive_update(true);
    });

    xscrollable_classes_init();
    state_change_init();
    pack_animation_init();
    map_filter_init();

}

function xscrollable_classes_init() {
    $(".js-x-scrollable-classes").on("scroll js-x-scrollable-classes-scroll", function () {
        var cur = $(this).scrollLeft();
        $(this).removeClass('x-scrollable-start x-scrollable-end');
        if (cur == 0) {
            $(this).addClass('x-scrollable-start');
        } else {
            var max = $(this)[0].scrollWidth - $(this)[0].offsetWidth;
            if (cur >= max) {
                $(this).addClass('x-scrollable-end');
            }
        }
    });
    $(".js-x-scrollable-classes").addClass("x-scrollable-inited").trigger("js-x-scrollable-classes-scroll");
}

function state_change_init() {
    $('.js-state-change').on('state-change', function(){
        if (!$(this).data('state-changing')) {
            var $all = $(this).children();
            var $active = $all.filter('.active');
            var $next = $active.next();
            if (!$next.length) {
                $next = $all.first();
            }
            var $next2 = $next.next();
            if (!$next2.length) {
                $next2 = $all.first();
            }
            var $prev = $active.prev();
            if (!$prev.length) {
                $prev = $all.last();
            }
            $prev.removeClass('active-prev');
            $active.removeClass('active');
            $active.addClass('active-prev');
            $next.removeClass('active-next');
            $next2.addClass('active-next');
            $next.addClass('active');
            $(this).toggleClass('state');
        }
    }).filter('.js-state-autoplay-onload').each(function(){
        var delay = $(this).data('delay');
        var $this = $(this);
        $(this).trigger('state-change');
        var interval = setInterval(function(){
            $this.trigger('state-change');
        }, delay);
        $this.data('state-invertal', interval);
    });
    $('.js-state-change').on('transitionstart', function(){
        $(this).data('state-changing', true);
    }).on('transitionend', function(){
        $(this).data('state-changing', false);
    })
    $(document).on('mouseenter', '.js-state-change-onhover', function(){
        var $this = $(this);
        $this.data('state-change-timestamp', new Date().getTime());
        delay(function(){
            $this.trigger('state-change');
        }, 100);
    });
    $(document).on('mouseleave', '.js-state-change-onhover', function(){
        var $this = $(this);
        //console.log($this.data('state-change-timestamp'), new Date().getTime() - $this.data('state-change-timestamp'));
        if ($this.data('state-change-timestamp') && new Date().getTime() - $this.data('state-change-timestamp') > 100) {
            delay(function(){
                $this[0].offsetHeight;
                $this.trigger('state-change');
            }, 0);
        }
        else {
            delay(function(){}, 0);
        }
    });
    $(document).on('mouseenter', '.js-state-autoplay-onhover', function(){
        var delay = $(this).data('delay');
        var $this = $(this);
        $(this).trigger('state-change');
        var interval = setInterval(function(){
            $this.trigger('state-change');
        }, delay);
        $this.data('state-invertal', interval);
    });
    $(document).on('mouseleave', '.js-state-autoplay-onhover', function(){
        clearInterval($(this).data('state-invertal'));
    });
}

function pack_animation_init() {

    var tl = gsap.timeline();
    var hm_ease = CustomEase.create("custom", "M0,0 C0,0 0.016,0.675 0.052,1.115 0.089,1.498 0.156,1.258 0.16,1.24 0.189,1.111 0.175,1.111 0.202,0.978 0.234,0.818 0.28,0.842 0.307,0.882 0.335,0.924 0.349,0.965 0.38,1.006 0.448,1.138 0.484,1.022 0.53,0.997 0.592,0.922 0.669,0.987 0.725,1.004 0.844,1.04 1,1 1,1 ");

    tl.timeScale(0.75);

    $(document).on('mouseenter', '.hm-pack', function () {
        delay(function () {
            tl.progress(1).progress(0);
            tl.pause();
            tl.addLabel("hm")
                .to(".hm-pack__image", {
                    duration: 0.12,
                    scale: 0.7,
                    rotation: -10,
                    onComplete: function () {
                        $('.hm-pack').addClass('lighten');
                    }
                }, 'hm')
                .to(".hm-pack__image", {
                    duration: 1.2,
                    ease: hm_ease,
                    scale: 1.2
                })
                .to(".hm-pack__glow", {
                    duration: 0.2,
                    opacity: 1,
                    onStart: function () {
                        $(this.targets()).removeClass('hidden');
                    }
                }, 'hm+=0.075')
                .fromTo(".hm-pack__tabs", {
                        scale: 2,
                        opacity: 0
                    }, {
                        duration: 0.4,
                        ease: Power2.easeOut,
                        scale: 1,
                        opacity: 1
                    }
                    , 'hm+=0.075')
                .fromTo(".hm-pack__tab-inner", {
                        scale: 1 / 2
                    }, {
                        duration: 0.4,
                        ease: Power2.easeOut,
                        scale: 1
                    }
                    , 'hm+=0.075')
                .to(".hm-pack__image-shadow", {
                    duration: 0.3,
                    opacity: 0
                }, 'hm+=0.12');
            tl.play('hm');
            $('body').addClass('hm-animating');
        }, 100);
    });

    $(document).on('mouseleave', '.hm-pack', function () {
        delay(function () {
            tl.pause();
            tl.addLabel("hmb")
                .to(".hm-pack__image", {
                    duration: 1,
                    ease: "elastic.out(1, 0.3)",
                    scale: 1,
                    rotation: 0,
                    onStart: function () {
                        $('.hm-pack').removeClass('lighten');
                    },
                    onComplete: function () {
                        $('body').removeClass('hm-animating');
                        $('.hm-pack').removeClass('lighten');
                    }
                }, 'hmb')
                .to(".hm-pack__glow", {
                    duration: 0.2,
                    opacity: 0,
                    onComplete: function () {
                        $(this.targets()).addClass('hidden');
                    }
                }, 'hmb')
                .to(".hm-pack__tabs", {
                        duration: 0.2,
                        scale: 0.1,
                        opacity: 0
                    }
                    , 'hmb')
                .to(".hm-pack__tab-inner", {
                        duration: 0.2,
                        scale: 1 / 0.5
                    }
                    , 'hmb')
                .to(".hm-pack__image-shadow", {duration: 0.3, opacity: 1}, 'hmb+=0.1');
            tl.play('hmb');
        }, 100);
    });
}

function map_filter_init() {

    $(document).on("change", ".js-ddbox-sku input", function (e) {
        var $map = $('.js-map');
        var $form = $(this).closest('.form');
        // if (!$form.attr('data-points-url-default')) {
        //     $form.attr('data-points-url-default', $('.js-map').attr('data-points-url'));
        // }
        // var url = $form.attr('data-points-url-default') + '?' + $form.serialize();
        $map.attr('data-points-url-data', $form.serialize());
        $map.data("auto-bounds", false);
        $map.trigger('map-update');
    });

    $(document).on("change", ".js-ddbox-region input", function (e) {
        var $map = $('.js-map');
        var map = $map.data('map');
        var region_id = $(".js-ddbox-region input:checked").val();
        var geoRegions = $map.data('regions');
        var regions = ymaps.geoQuery(geoRegions);
        regions.search('properties.osmId = '+region_id).each(function(region){
            var p = new ymaps.GeoObject({
                geometry: region.geometry,
            }, {
                opacity: 0,
            });
            map.geoObjects.add(p);
            map.setBounds(p.geometry.getBounds(), {checkZoomRange: true, duration: 400, margin: 20});
            map.geoObjects.remove(p);
            return false;
        });
        //$map.trigger('map-suggest-update');
    });

    $(document).on("ddbox-select ddbox-deselect", ".js-ddbox", function (e) {
        var $s = $(this).find('.ddbox__selection');
        $s.find('.ddbox__value-image').remove();
        var $di = $('<div class="ddbox__value-image"><div class="ddbox__value-image-inner"></div></div>');
        var $i = $di.find('.ddbox__value-image-inner');
        var found = 0;
        $(this).find('.sbcheckbox.checked .js-ddbox-item-img').each(function () {
            $i.append($(this).clone());
            found++;
        });
        if (found) {
            $s.prepend($di);
        }
    });

    $(document).on('map-init', '.js-map[data-points-url]', function (e) {
        var $map = $(this);
        var map = $map.data('map');
        var $ddbox = $('.js-ddbox-region');
        var $ch = $ddbox.find('.sbcheckboxes__inner');
        ymaps.regions.load('RU', {
            lang: 'ru',
            quality: 0
        }).then(function (result) {
            var r = [];
            var regions = result.geoObjects;
            $map.data('regions', regions);
            // var qregions = ymaps.geoQuery(regions);
            // qregions.sort('name');
            regions.each(function (region) {
                r.push({id: region.properties.get('osmId'), name: region.properties.get('name')});
            });
            function compare( a, b ) {
                if ( a.name < b.name ){
                    return -1;
                }
                if ( a.name > b.name ){
                    return 1;
                }
                return 0;
            }

            r.sort( compare );
            sorting_array = ['Москва', 'Московская область', 'Санкт-Петербург'];
            function compare2( a, b ) {
                var a1 = sorting_array.indexOf(a.name);
                var b1 = sorting_array.indexOf(b.name);
                if (a1 < 0) {
                    a1 = Infinity;
                }
                if (b1 < 0) {
                    b1 = Infinity;
                }
                return a1 - b1;
            }
            r.sort( compare2 );
            $.each(r, function (k,v) {
                var html = '<label class="sbcheckbox sbcheckbox--highlight checkbox-plain-js js-ddbox-input'+((k < sorting_array.length)?' font-bold':'')+'">'+
                    '<input type="radio" name="region" value="'+v.id+'">'+//v.id
                    '<span class="js-ddbox-value text-ellipsis">'+v.name+'</span>'+
                    '</label>';
                $ch.append(html);
                //console.log(region.properties.get('name') + ' -> ' + region.properties.get('osmId'));
            });
            $ddbox.removeClass('disabled');
            checkbox_plain_init(null, $ddbox);
            ipdetect(ddbox_region_current_select, 60*60*1000*6);
            // var regions = result.geoObjects; // ссылка на коллекцию GeoObjectCollection
            // map.geoObjects.add(regions);
        }, function () {
            console.log('No response');
        });
    });

    $(document).on('map-init map-update', '.js-map[data-points-url]', function (e) {
        var $map = $(this);
        var map = $map.data('map');
        $.ajax({
            url: $map.attr('data-points-url'),
            data: $map.attr('data-points-url-data'),
            success: function (response) {
                var points = [];
                map.geoObjects_pl = [];
                map.geoObjects.removeAll();
                $.each(response, function (k, v) {
                    var point = v.ll;
                    if (point) {
                        var html = '';
                        html += '<div><div class="p-info">';
                        if (v.logo) {
                            html += '<div class="p-info__logo">';
                            html += '<img src="'+v.logo+'" alt="">';
                            html += '</div>';
                        }
                        html += '<div class="p-info__content">';
                        if (v.name) {
                            html += '<div class="p-info__title">'+v.name+'</div>';
                        }
                        html += '<div class="p-info__summary">';
                        if (v.address) {
                            html += '<p>'+v.address+'</p>';
                        }
                        if (v.phone) {
                            html += '<p><strong>Тел.</strong> '+v.phone+'</p>';
                        }
                        //html += '<p><strong>Пн-Пт:</strong> 10:00 - 22:00<br><strong>Сб-Вс:</strong> 10:00 - 20:00</p>';
                        html += '</div>';
                        html += '</div>';
                        html += '</div></div>';
                        var placemark = map_build_placemark(map, point, $(html));
                        map.geoObjects_pl.push(placemark);
                    }
                });
                map_geoObjects_update($map, map);
                $map.data("auto-bounds", true);
            }
        });
    });

    $(document).on('map-init map-suggest-update', '.js-suggest-view-map', function (e) {
        var map = $(this).data('map');
        var suggestView = $(this).data('suggestView')
        if (suggestView) {
            suggestView.destroy();
        }
        var input = $('#suggest-view-input')[0];
        if (input) {
            var suggestView = new ymaps.SuggestView(input, {results: 10/*, boundedBy: map.getBounds()*/});
            suggestView.events.add('select', function (e) {
                map_goto_address(map, /*$('.js-ddbox-region .js-ddbox-input input:checked').val() + ' ' + */e.get('item').value);
            });
            // suggestView.events.add('optionschange', function (e) {
            //     console.log(e);
            //     //var query = e.get('item').value;
            // });

            suggestView.state.events.add('change', function () {
                var activeIndex = suggestView.state.get('activeIndex');
                if (typeof activeIndex == 'number') {
                    activeItem = suggestView.state.get('items')[activeIndex];
                    console.log('change', activeItem.value);
                    // if (activeItem && activeItem.value != input.value) {
                    //     input.value = activeItem.value;
                    // }
                }
            });

            $(input).on('keyup', function (e) {
                if (e.keyCode === 13) {
                    console.log('keyup', activeIndex);
                    var activeIndex = suggestView.state.get('activeIndex');
                    if (typeof activeIndex !== 'number') {
                        activeIndex = 0;
                        // var e = jQuery.Event("keydown");
                        // e.which = 40;
                        // $(input).trigger(e);
                        // var item = $(input).siblings('ymaps').find('[data-index="0"]');
                        // console.log(item);
                        // item.trigger('click');
                        // activeItem = suggestView.state.get('items')[activeIndex];
                        // suggestView.events.fire('select', new ymaps.MapEvent({ type: 'select', item: activeItem, target: input }));
                    }
                    activeItem = suggestView.state.get('items')[activeIndex];
                    if (activeItem && activeItem.value) {
                        console.log(activeItem, activeItem.value);
                        map_goto_address(map, activeItem.value);
                        input.value = activeItem.value;//activeItem.displayName;
                        //suggestView.events.fire('select', new ymaps.MapEvent({ type: 'select', item: activeItem }, map));
                        //$(input).trigger('blur').trigger('change');
                        //suggestView.events.fire('select', new ymaps.MapEvent({ type: 'select', item: activeItem, target: suggestView }));
                        setTimeout(function(){
                            $(input).trigger('blur');
                        }, 200);
                    }
                }
            });
            $(this).data('suggestView', suggestView);
        }
    });
}

function map_goto_address(map, value) {
    $.ajax({
        url: 'https://geocode-maps.yandex.ru/1.x/?apikey=' + $('body').data('ymaps-api-key') + '&format=json&geocode=' + value,
        success: function (response) {
            var point = response.response.GeoObjectCollection.featureMember[0].GeoObject.Point.pos.split(' ').reverse();
            $.each(point, function (k, v) {
                point[k] = parseFloat(v);
            });
            //var map = $('.js-suggest-view-map').data('map');
            map.setZoom(15);
            map.setCenter(point);
        }
    });
}

function dadata_iplocate(ip) {
    var token = "b2e2aef0cb5daccf3ef93cc4f43176da6d7f6361";
    var serviceUrl = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/iplocate/address";
    if (ip) {
        serviceUrl += "?ip=" + ip;
    }
    var params = {
        type: "GET",
        contentType: "application/json",
        headers: {
            "Authorization": "Token " + token
        }
    };
    return $.ajax(serviceUrl, params);
}

function ipdetect(callback, ttl) {
    var object = '';
    if (localStorage) {
        object = localStorage.getItem('iplocate');
        if (object) {
            object = JSON.parse(object);
        }
    }
    if (object && object.value && object.timestamp + ttl > new Date().getTime()) {
        if (typeof callback === "function") {
            callback.apply(object.value);
        }
    }
    else {
        var ip = '';
        dadata_iplocate(ip).done(function(response) {
            var object = {value: response, timestamp: new Date().getTime()};
            localStorage.setItem('iplocate', JSON.stringify(object));
            if (typeof callback === "function") {
                callback.apply(response);
            }
        })
        .fail(function(jqXHR, textStatus, errorThrown) {
            // console.log(textStatus);
            // console.log(errorThrown);
        });
    }
}

function ddbox_region_current_select() {
    var response = this;
    var region_name = response.location.data.region + ((response.location.data.region_type_full)?' ' + response.location.data.region_type_full:'');
    if (region_name) {
        $('.js-ddbox-region .js-ddbox-value').each(function(){
            if ($.trim($(this).text()).toLowerCase() == region_name.toLowerCase()) {
                $(this).closest('.js-ddbox-input').find('input').attr('checked', true).prop('checked', true);
                ddbox_select($(this).closest('.js-ddbox'));
                return false;
            }
        });
    }
}


function countdown_init(items) {

    function startTimer(element, duration) {
        var timer = duration, minutes, seconds;
        startTimer.interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            element.textContent = minutes + ":" + seconds;

            if (--timer < 0) {
                timer = duration;
                clearInterval(startTimer.interval);
                blazy_callback(element);
            }
        }, 1000);
    }

    if (typeof items === 'undefined') {
        items = document.querySelectorAll('[data-countdown]:not(.countdown-inited)');
    }
    items.forEach(function(element){
        startTimer(element, element.getAttribute('data-countdown'));
        element.classList.add('countdown-inited');
    })
}


function upload_init() {

    $(document).on("change", ".js-upload", function (e) { // change
        e.preventDefault();
        var $o = $($(this).data('upload-container'));
        if (!$o.length) {
            $o = $(this).closest('.js-upload-container');
        }
        $(this).removeClass('loaded');
        $o.removeClass('loaded');
        $o.find('.js-upload-img-container img').remove();
        if (this.files.length) {
            $(this).addClass('loaded');
            $o.addClass('loaded');
            $o.find('.js-upload-img-container').append('<img>');
            upload_load_image(this, $o.find('.js-upload-img-container img')[0]);
            $(this).blur();
        }
        // console.log('js-upload change');
    });

    $(document).on("click", ".js-upload-clear", function (e) { // change
        e.preventDefault();
        var $o = $(this).closest('.js-upload-container');
        var $input = $($o.data('upload-input'));
        if (!$input.length) {
            $input = $o.find('.js-upload');
        }
        upload_clear($input);
    });

    $(document).on("dragenter dragover", ".js-upload", function (e) {
        var $o = $(this).closest('.js-upload-container');
        $o.addClass('is-dragover');
    });

    $(document).on("dragleave drop", ".js-upload", function (e) {
        var $o = $(this).closest('.js-upload-container');
        $o.removeClass('is-dragover');
    });
};

function upload_clear(input) {
    var $input = $(input);
    var $o = $($input.data('upload-container'));
    if (!$o.length) {
        $o = $input.closest('.js-upload-container');
    }
    $input.each(function(i){
        var id = 'upload-clear-id'+(new Date()).getTime()+'-'+i;
        $(this).attr('data-upload-clear-id', id);
        $(this).replaceWith($('<div>').append($(this).clone()).html());
        $('[data-upload-clear-id="'+id+'"]').removeClass('loaded').trigger('upload-clear');
    });
    $o.removeClass('loaded');
};

function upload_load_image(input, img) {
    var input, file, fr, img;

    if (typeof window.FileReader !== 'function') {
        alert("The file API isn't supported on this browser yet.");
        return;
    }

    if (!input) {
        alert("Um, couldn't find the imgfile element.");
    }
    else if (!input.files) {
        alert("This browser doesn't seem to support the `files` property of file inputs.");
    }
    else if (!input.files[0]) {
        //alert("Please select a file.");
    }
    else {
        file = input.files[0];
        var accept = input.getAttribute('accept');
        if (!accept || file.type.indexOf(accept.split('*')[0]) === 0 || accept.indexOf(file.type) !== -1) {
            fr = new FileReader();
            fr.onload = createImage;
            fr.readAsDataURL(file);
        }
        else {
            upload_clear(input);
        }
    }

    function createImage() {
        if (img) {
            img.onload = imageLoaded;
            img.src = fr.result;
            $(img).parent().css('background-image', fr.result);
        }
    }

    function imageLoaded() {

    }
};