
/*

Custom Checkboxes

 */

function checkbox_plain_init($o, context) {
    if (!$o) {
        $o = context?$('.checkbox-plain-js', context):$('.checkbox-plain-js');
    }
    $o = $o.not(".checkbox-plain-js-inited");
    $o.find("[type='checkbox'], [type='radio']").on("change change-pseudo", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        var name = $(this).attr("name");
        if ($(this).prop("type") == 'radio') {
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            $radios = $radios.not(':checked');
            $radios.closest(".checkbox-plain-js").removeClass("checked");
        }
        if ($(this).prop("checked")) $o.addClass("checked");
        else $o.removeClass("checked");
        if ($(this).prop("disabled")) $o.addClass("disabled");
        else $o.removeClass("disabled");
    }).on("focus", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        if (!$(this).prop("disabled")) $o.addClass("focused");
    }).on("blur", function(e){
        var $o = $(this).closest(".checkbox-plain-js");
        $o.removeClass("focused");
    }).on('click', function(e){
        var $input = $(this).data("uncheckable");
        if (typeof $(this).data("uncheckable") !== 'undefined') {
            var previousValue = $(this).attr('data-previousValue');
            var name = $(this).attr('name');
            var $radios = $(this).closest("form").find("[name='"+name+"']");
            if (!$radios.length) $radios = $("input[name='"+name+"']");
            if (previousValue == 'checked') {
                $(this).prop('checked', false).trigger('change');
                $(this).attr('data-previousValue', false);
            } else {
                $radios.attr('data-previousValue', false);
                $(this).attr('data-previousValue', 'checked');
            }
        }
    }).each(function(){
        var $o = $(this).closest(".checkbox-plain-js");
        if ($(this).attr("checked")) $o.addClass("checked");//?? prop
        if ($(this).attr("disabled")) $o.addClass("disabled");//?? prop
    });
    $o.addClass("checkbox-plain-js-inited");
}





/*
* Запрос и выдача элементов дропдауна при поиске внутри поля ввода дропдауна
* */

function ddbox_textfield_query($input) {

    var method = "get";
    var data = null;
    var url = $input.attr("data-action-url");
    if ($input.attr("data-action-method")) {
        method = $input.attr("data-action-method");
    }
    var $form = $input.closest('form')
    if ($form.length) {
        data = $form.serialize();
    }

    if ($input.data('xhr')) {
        var xhr = $input.data('xhr');
        if (xhr && xhr.readyState != 4) {
            xhr.abort();
        }
    }
    var xhr = $.ajax({
        url: url,
        type: method,
        data: data,
        dataType: 'json',
        cache: false,
        contentType: false,
        processData: false,
        beforeSend: function () {
        },
        complete: function (response) {
            $input.trigger("ajax-complete");
        },
        success: function (response) {
            if (response.html) {
                var $ddbox = $input.closest('.ddbox');
                $ddbox.find('.ddbox__dropdown').html(response.html);
                bind_widgets();
                $ddbox.removeClass('opened-blocked');
                ddbox_open($ddbox);
                ddbox_select($ddbox);
            }
            $input.trigger("ajax-success", response);
        },
        error: function (jqXHR) {
            $input.trigger("ajax-error");
        }
    });
    $input.data('xhr', xhr);
}


/*
* Инициализация дропдаунов
* */

function ddbox_init(context) {
    _ddbox_init(context);

    if (!$("body").hasClass("ddbox-inited")) {
        ddbox_events();
        $("body").addClass("ddbox-inited");
    }
}

function _ddbox_init(context) {
    var $o = context?$('.js-ddbox', context):$('.js-ddbox');
    $o.not('.js-ddbox-inited').each(function(){
        ddbox_select($(this), true);
        // if ($(this).closest('.js-filter').length) {
        //     var back_title = $(this).data('ddbox-back-title');
        //     $(this).prepend('' +
        //         '<div class="ddbox__back js-ddbox-close">\n' +
        //         '    <svg class="icon icon--arrow-left">\n' +
        //         '        <use xlink:href="#icon-arrow-left"></use>\n' +
        //         '    </svg>\n' +
        //         '    <span class="filter__section-title-link link--ib link--dotted">\n' +
        //         '        ' + ((back_title) ? back_title : 'Все фильтры') + '\n' +
        //         '    </span>\n' +
        //         '</div>');
        //     icons_init($(this).find('.ddbox__back'));
        // }
        $(this).addClass('js-ddbox-inited');
    });
}


/*
* Подключение событий
* */

function ddbox_events() {

    $(document).on('click focus', '.js-ddbox .ddbox__selection', function(e){
        var $o = $(this).closest('.ddbox');
        if (!$o.hasClass('disabled')) {
            ddbox_close($(".ddbox.opened").not($o));
            if (!$o.hasClass('js-ddbox-textfield')) {
                ddbox_toggle($o);
            }
        }
    });

    $(document).on('click focus', '.js-ddbox .ddbox__reset', function(e){
        var $o = $(this).closest('.ddbox');
        e.stopPropagation();
        ddbox_reset($o);
    });

    $(document).on('click', '.js-ddbox-close', function(e){
        var $o = $(this).closest('.ddbox');
        if (!$o.hasClass('disabled')) {
            ddbox_close($(".ddbox.opened"));
        }
    });

    $(document).on('click', '.js-ddbox-close-all', function(e){
        ddbox_close($(".ddbox.opened"));
    });

    $(document).on('click', '*', function(e){
        var $o = $(e.target).closest('.ddbox');
        if (!$o.length && $('.ddbox-is-opened .ddbox.opened').first().css('position') != 'fixed') {
            ddbox_close($(".ddbox.opened"));
        }
    });

    $(document).on('change change-pseudo', '.js-ddbox :input', function(e){
        var $o = $(e.target).closest('.ddbox');
        if (!$o.data('ddbox-select-manual')) {
            ddbox_select($o);
        }
    });

    $(document).on('click', '.js-ddbox-select', function(e){
        e.preventDefault();
        var $o = $(e.target).closest('.ddbox');
        ddbox_select($o);
    });

    $(document).on('click', '.js-ddbox-close', function(e){
        e.preventDefault();
        var $o = $(e.target).closest('.ddbox');
        ddbox_close($o);
    });

    $(document).on("keyup", function(e) {
        if (e.keyCode == 27) { //27 = keycode for escape
            ddbox_close($(".ddbox.opened"));
        }
    });
}

/*
* Получение значения (in_input - true, если нужно получить значение в виде списка value атрибутов, а не визуально привлекательного для дропдауна)
* */

function ddbox_value_get($o, in_input) {
    var value = '';
    var values = [];
    var input_values = [];
    var $inputs = $o.find('.js-ddbox-input');
    $inputs.each(function(){
        var $input = $(this);
        if ($input.prop('type') != 'text') {
            $input = $input.find('input').first();
        }
        if ($input.length) {
            if ((['checkbox', 'radio'].indexOf($input.prop('type')) !== -1  && $input.prop('checked')) || $input.prop('type') == 'text') {
                input_values.push(ddbox_input_value_get($input));
                values.push(ddbox_input_visual_value_get($input));
            }
        }
    });
    $o.attr('data-ddbox-values-count', values.length);
    var prepend_placeholder = $o.data('ddbox-prepend-placeholder');
    var $placeholder = $o.find('.js-ddbox-placeholder');
    var aggregate = $o.data('ddbox-aggregate');
    var label = '';
    if (aggregate && aggregate <= values.length) {
        label = 'Выбрано';
        value = values.length;
    }
    else {
        var sep = $o.data('ddbox-value-separator');
        value = values.join(sep !== undefined?sep:', ');
        if (values.length && !values[0] && !values[1]) {
            value = '';
        }
    }
    if (prepend_placeholder && $placeholder.length) {
        label = $placeholder.html();
    }
    if (label && value) {
        value = label + ': ' + value;
    }
    if (in_input) {
        if (input_values.length < 2) {
            return input_values.pop();
        }
        return input_values;
    }
    return value;
}

function ddbox_input_visual_value_get($input) {
    var value = null;
    var $ddbox_input = $input.closest('.js-ddbox-input');
    var $v1 = $input.attr('data-ddbox-value');
    var $v2 = $ddbox_input.find('[data-ddbox-value]');
    var $v3 = $ddbox_input.find('.js-ddbox-value');
    if ($v1) {
        value = $v1;
    }
    else if ($v2.length) {
        value = $v2.attr('data-ddbox-value');
    }
    else if ($v3.length) {
        value = $v3.html();
    }
    else if ($input.prop('type') == 'text') {
        var input_format = $input.data('input-format');
        var input_format_function = $input.data('input-format-function');
        if (input_format_function && typeof window[input_format_function] === 'function') {
            value = window[input_format_function]($input.val());
        }
        else if (input_format) {
            value = input_format.to($input.val());
        }
        else {
            value = $input.val();
        }
    }
    return value;
}

function ddbox_input_value_get($input) {
    return $input.val();
}

function ddbox_toggle($o) {
    if ($o.hasClass('opened')) {
        ddbox_close($o);
    }
    else {
        ddbox_open($o);
    }
}

function ddbox_open($o) {
    var $o_b = $o.not('.opened').not('.opened-blocked');
    if ($o_b.length) {
        $o_b.addClass('opened');
        $('html').addClass('ddbox-is-opened');
    }
    $o.trigger('ddbox-open');
}

function ddbox_close($o) {
    var $o_b = $o.filter('.opened').not('.opened-blocked');
    if ($o_b.length) {
        $o_b.removeClass('opened');
        $('html').removeClass('ddbox-is-opened');
        if ($o_b.data('ddbox-select-on-close')) {
            ddbox_select($o_b);
        }
    }
    $o.trigger('ddbox-close');
}

function ddbox_select($o, init) {
    if (init === undefined) {
        init = false;
    }
    $o = $o.not('.disabled');
    $o_sel = $o.find('.js-ddbox-selection');
    var value = ddbox_value_get($o);
    var input_value = ddbox_value_get($o, true);
    var value_default = $o_sel.data('default-value');
    var is_default = input_value == value_default;
    if (Array.isArray(input_value) && Array.isArray(value_default)) {
        is_default = input_value.equals(value_default);
    }
    // if (value && !is_default) {
    //     $o_sel.addClass('active').html(value);
    //     $o.find('.js-ddbox-placeholder').removeClass('active');
    //     $o.addClass('selected').trigger('ddbox-select', [init]);
    // }
    // else {
    //     $o_sel.removeClass('active').html('');
    //     $o.find('.js-ddbox-placeholder').addClass('active');
    //     $o.removeClass('selected').trigger('ddbox-deselect', [init]);
    // }
    if (value && !is_default) {
        $o_sel.addClass('active').html(value);
        $o.find('.js-ddbox-placeholder').removeClass('active');
        $o.addClass('selected').trigger('ddbox-select', [init]);
        //console.log('geo ok!');
    }
    else {
        $o_sel.removeClass('active').html('');
        $o.find('.js-ddbox-placeholder').addClass('active');
        //document.querySelector('div.map-form-block div.ddbox__value--placeholder').classList.add('active');
        $o.removeClass('selected').trigger('ddbox-deselect', [init]);
        //console.log('geo fail!');
    }
    if ($o.data('ddbox-close-on-select')) {
        ddbox_close($o);
    }
}

function ddbox_disable($o) {
    ddbox_close($o);
    $o.addClass('disabled').trigger('ddbox-disable');
}

function ddbox_enable($o) {
    $o.removeClass('disabled').trigger('ddbox-enable');
}

function ddbox_reset($o) {
    $o = $o.not('.disabled');
    $o.each(function(){
        var $o = $(this);
        input_reset($o);
        ddbox_select($o);
        ddbox_close($o);
        $o.trigger('ddbox-reset');
    });
}


function input_reset($o) {

    $o.find('input').each(function(i,item){
        var $input = $(this);
        if (!$input.prop('disabled')) {
            // if (!$input.prop('type') == 'text') {
            //     $input.val($input.attr('data-default-value'));
            // }
            /*else */if (['checkbox', 'radio'].indexOf($input.prop('type')) !== -1) {
                var new_state = $input.data('default-checked') !== undefined;
                var changed = $input.prop('checked') !== new_state;
                if (changed) {
                    $input.attr('checked', new_state);
                    $input.prop('checked', new_state);
                    $input.trigger('change');
                }
            }
        }
    });

    $o.find(':text').not('[disabled]').each(function(i,item){
        var $input = $(this);
        var new_value = $input.data('default-value');
        var changed = $input.val() !== new_value;
        if (changed) {
            $input.removeAttr('value');
            $input.val(new_value);
            $input.trigger('change');
        }
    });
}


// Warn if overriding existing method
if (Array.prototype.equals) {
    console.warn("Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there's a framework conflict or you've got double inclusions in your code.");
}
// attach the .equals method to Array's prototype to call it on any array
Array.prototype.equals = function (array) {
    // if the other array is a falsy value, return
    if (!array)
        return false;

    // compare lengths - can save a lot of time
    if (this.length != array.length)
        return false;

    for (var i = 0, l=this.length; i < l; i++) {
        // Check if we have nested arrays
        if (this[i] instanceof Array && array[i] instanceof Array) {
            // recurse into the nested arrays
            if (!this[i].equals(array[i]))
                return false;
        }
        else if (this[i] != array[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
}
// Hide method from for-in loops
Object.defineProperty(Array.prototype, "equals", {enumerable: false});

