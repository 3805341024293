/*

 Input Masks

 */

function mask_init(context) {
    if (typeof Inputmask !== "undefined") {

        if (!$("body").hasClass("inputmask-inited")) {
            Inputmask.extendDefaults({
                showMaskOnHover: true,
                showMaskOnFocus: true,
                onKeyDown: function(event, buffer, caretPos, opts) {
                    if (event && event.target && !$(event.target).inputmask('isComplete')) {
                        $(event.target).removeClass("mask-complete");
                        $(event.target).removeClass("mask-incomplete");
                    }
                },
                oncomplete: function(event) {
                    if (event && event.target) {
                        $(event.target).addClass("mask-complete");
                        $(event.target).removeClass("mask-incomplete");
                    }
                },
                onincomplete: function(event) {
                    if (event && event.target) {
                        $(event.target).removeClass("mask-complete");
                        $(event.target).addClass("mask-incomplete");
                    }
                },
            });

            $("body").addClass("inputmask-inited")
        }

        $(".js-mask-phone-ru", context).filter(":not(.mask-inited)").inputmask({
            definitions: {
                '#': {
                    validator: "[0-9]",
                    cardinality: 1
                }
            },
            mask: "+7 (###) ###-##-##",
            placeholder: "_",
            onBeforePaste: function (pastedValue, opts) {
                var processedValue = pastedValue;
                processedValue = processedValue.replace(/^8/, '+7');
                return processedValue;
            }
        }).on("keyup", function(event) {
            delay(function(){
                var $this = $(event.target);
                var value = $(event.target).inputmask("unmaskedvalue");
                if (value.length === 1 && value === "8") {
                    $this.inputmask("setvalue", "+7 ");
                    $this.focus();
                    $this.selectRange(3);
                }
            }, 100);
        }).addClass("mask-inited");

    }
}

$.fn.getCursorPosition = function() {
    var input = this.get(0);
    if (!input) return; // No (input) element found
    if ('selectionStart' in input) {
        // Standard-compliant browsers
        return input.selectionStart;
    } else if (document.selection) {
        // IE
        input.focus();
        var sel = document.selection.createRange();
        var selLen = document.selection.createRange().text.length;
        sel.moveStart('character', -input.value.length);
        return sel.text.length - selLen;
    }
};

$.fn.selectRange = function(start, end) {
    if(end === undefined) {
        end = start;
    }
    return this.each(function() {
        if('selectionStart' in this) {
            this.selectionStart = start;
            this.selectionEnd = end;
        } else if(this.setSelectionRange) {
            this.setSelectionRange(start, end);
        } else if(this.createTextRange) {
            var range = this.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    });
};






/*
* Form handlers
* */


function form_process_init() {

    $(document).on("submit.valid click perform-action", ".js-action", function(e){
        var $this = $(this);
        if ($this.is("form") && e.type != "submit") {
            return true;
        }
        e.preventDefault();
        var url = $this.attr("href");
        var method = "get";
        var data = null;

        $this.find('.js-file-upload-hash').val(new Date().getTime());

        if ($this.is("form")) {
            url = $this.attr("action");
            if ($this.attr("method")) {
                method = $this.attr("method");
            }
        }
        if ($this.attr("data-action-url")) {
            url = $this.attr("data-action-url");
        }
        if ($this.attr("data-action-method")) {
            method = $this.attr("data-action-method");
        }

        if ($this.is("form")) {
            data = $this.serialize();

            if (method.toLowerCase() != 'get' && window.FormData !== undefined) {
                data = new FormData($this[0]);
            }
            $this.data('serialized', data);
            $this.data('serializedArray', $this.serializeArray());
        }

        if ($this.find('.js-file-upload-start').length) {
            form_process_before_send($this);
            $this.find('.js-file-upload-start').trigger('click');
        }
        else {
            $.ajax({
                url: url,
                type: method,
                data: data,
                dataType: 'json',
                cache: false,
                contentType: false,
                processData: false,
                beforeSend: function () {
                    form_process_before_send($this);
                },
                complete: function (response) {
                    form_process_complete($this, response);
                },
                success: function (response) {
                    form_process_errors_clear($this);
                    form_process_success($this, response);
                },
                error: function (jqXHR) {
                    form_process_errors_clear($this);
                    form_process_errors($this, jqXHR);
                }
            });
        }
    });

    $(document).on("after-action-success", ".js-action-reset-after", function(e, response){
        var is_success = form_response_is_success($(this), response);
        if (is_success) {
            $(this)[0].reset();
        }
    });

    $(document).on("change", ".js-submit-on-input-change :input", function(e){
        $(this).closest('form').trigger('submit');
    });
}

function form_process_before_send($this) {
    $this.find(":input").prop("disabled", true).closest(".textfield-wrapper").addClass("disabled");
    $this.find(".js-form-disable-on-submit").addClass("disabled");
    var $btn = $this.find(".loader-on-submit");
    if (window['loader_add']) {
        loader_add($btn);
    }
}

function form_process_complete($this, response) {
    $this.find(":input").not("[data-disabled]").prop("disabled", false).closest(".textfield-wrapper").removeClass("disabled");
    $this.find(".js-form-disable-on-submit").not("[data-disabled]").removeClass("disabled");
    $this.find(":password").val("");
    var $btn = $this.find(".loader-on-submit");
    if (window['loader_remove']) {
        loader_remove($btn);
    }
    $this.trigger("after-action-complete", response);
}


function form_response_is_success($this, response) {
    var response_success = $this.data('response-success');
    var is_success = true;
    if (response_success) {
        $.each(response_success, function(k, v) {
            if (response[k] !== v) {
                is_success = false;
                return false;
            }
        });
    }
    return is_success;
}

function form_process_success($this, response) {
    var is_success = form_response_is_success($this, response);
    var popup_success = $this.data('popup-success');
    if (is_success) {
        if (popup_success) {
            $.fancybox.close();
            $.fancybox.open({
                'src': popup_success,
                'type': 'ajax',
                'opts': $.fancybox.options_modal
            });
        }
        else if (response.popup) {
            if (response.text) {
                $.fancybox.close();
                $.fancybox.open(response.text, $.fancybox.options_modal);
            }
        }
        else {
            var $message = $this.find(".js-message");
            if (!$message.length) {
                $message = $this.find($this.data("message"));
            }
            if (!$message.length) {
                $message = $($this.data("message"));
            }
            if ($message.length) {
                $this.find(".js-message-container").html(response.text);
                bind_widgets($this);
                $message.addClass("active");
                $this.addClass("inactive");
                if ($message.offset().top < $(window).scrollTop() + $(".header").outerHeight()) {
                    goto_object($message, null, $(".header").outerHeight()*-1);
                }
                if ($this.data("message-autoclose"))
                {
                    setTimeout(function(){
                        $message.removeClass("active");
                        $this.removeClass("inactive");
                    }, $this.data("message-autoclose"));
                }
            }
        }
    }
    else {
        var error_text;
        if (response.errors) {
            form_process_errors_show($this, response);
        }
        else if (response.text) {
            error_text = response.text;
        }
        else {
            error_text = 'Error occurs. Try again.';
        }
        if (error_text && $().fancybox) {
            $.fancybox.open('<div class="panel"><div class="panel__content">'+error_text+'</div></div>', $.fancybox.options_modal);
        }
    }
    $this.trigger("after-action-success", response);
}

function form_process_errors($this, jqXHR) {
    console.log(jqXHR.status, jqXHR.responseJSON);
    var error_text;
    if (jqXHR.status === 401) {
        error_text = 'Access denied.';
    }
    else if (jqXHR.status === 422) {
        form_process_errors_show($this, jqXHR.responseJSON);
    }
    else {
        error_text = 'Error occurs. Try again.';
    }
    if (error_text) {
        alert(error_text);
    }
    $this.trigger("after-action-error", jqXHR);
}

function form_process_errors_clear($this) {
    $this.find('.form-process-error').removeClass('error form-process-error');
    $this.find('.form__error--form-process-error').remove();
}

function form_process_errors_show($this, response) {
    var index = 0;
    form_process_errors_clear($this);
    $.each(response.errors, function(field_name, field_errors){
        $field = $this.find("[name='" + field_name + "']");
        $field.addClass("error form-process-error");
        if (!index) {
            setTimeout(function(){
                $field.trigger("focus");
            }, 100);
        }
        var $append_to = $field.closest('.form__item-field');
        if (!$append_to.length) {
            $append_to = $field.parent();
        }
        $append_to.find('.form__error').remove();
        if (!Array.isArray(field_errors)) {
            field_errors = [field_errors];
        }
        $.each(field_errors, function(error_index, error_text) {
            $append_to.append("<div id='"+field_name+"-error' class='form__error form__error--form-process-error'>" + error_text + "</div>");
        });
        index++;
    });
}



/*

 Form Validation

 */

function validate_init(context)
{
    if (!$.validator) return;

    if (!$("body").hasClass("validate-inited")) {

        $(document).on("reset", ".form-validate", function () {
            var $form = $(this);
            $form.find(".form__item, :input").removeClass("error");
            setTimeout(function () {
                $form.find(":input").trigger("change");
                $form.find(".checkbox-plain-js input[type='checkbox'], .checkbox-plain-js input[type='radio']").trigger("change-pseudo");
            }, 50);
        });

        $.extend($.validator.messages, {
            required: "Required field.",
            email: "Invalid E-mail.",
            uname: "Invalid name.",
            phone: "Uncompleted phone number.",
            phonecomplete: "Uncompleted phone number.",
            maskcomplete: "Fill this field completely.",
            datetime: "Incorrect date or time.",
            dateexist: "This date or time does not exist.",
            password: "Password must contain more than 6 symbols.",
            passwordconfirm: "Does not match with password.",
            remote: "Please fix this field.",
            url: "Please enter a valid URL.",
            dateonly: "Please enter a valid date.",
            date: "Please enter a valid date.",
            dateISO: "Please enter a valid date (ISO).",
            number: "Please enter a valid number.",
            digits: "Please enter only digits.",
            creditcard_expires: "Invalid expery date.",
            creditcard_cvc: "Invalid CVC format.",
            equalTo: "Please enter the same value again.",
            maxlength: $.validator.format("Please enter no more than {0} characters."),
            minlength: $.validator.format("Please enter at least {0} characters."),
            rangelength: $.validator.format("Please enter a value between {0} and {1} characters long."),
            range: $.validator.format("Please enter a value between {0} and {1}."),
            max: $.validator.format("Please enter a value less than or equal to {0}."),
            min: $.validator.format("Please enter a value greater than or equal to {0}."),
            step: $.validator.format("Please enter a multiple of {0}.")
        });

        var lang = $('html').attr('lang');
        switch (lang) {
            case 'ru':
                $.extend( $.validator.messages, {
                    required: "Обязательное поле.",
                    email: "Некорректный E-mail.",
                    uname: "Некорректно заполненное имя.",
                    phone: "Неполный номер телефона.",
                    phonecomplete: "Неполный номер телефона.",
                    maskcomplete: "Заполните поле полностью.",
                    dateonly: "Неверный формат даты.",
                    datetime: "Неверный формат даты и времени.",
                    dateexist: "Несуществующая дата и/или время.",
                    password: "Пароль должен быть больше 6 символов.",
                    passwordconfirm: "Не совпадает с паролем.",
                    remote: "Пожалуйста, введите правильное значение.",
                    url: "Пожалуйста, введите корректный URL.",
                    date: "Пожалуйста, введите корректную дату.",
                    dateISO: "Пожалуйста, введите корректную дату в формате ISO.",
                    number: "Пожалуйста, введите число.",
                    digits: "Пожалуйста, вводите только цифры.",
                    creditcard: "Неправильный номер карты.",
                    creditcard_expires: "Неправильный срок действия.",
                    creditcard_cvc: "Неверный формат CVC.",
                    equalTo: "Пожалуйста, введите такое же значение ещё раз.",
                    extension: "Пожалуйста, выберите файл с правильным расширением.",
                    maxlength: $.validator.format( "Пожалуйста, введите не больше {0} символов." ),
                    minlength: $.validator.format( "Пожалуйста, введите не меньше {0} символов." ),
                    rangelength: $.validator.format( "Пожалуйста, введите значение длиной от {0} до {1} символов." ),
                    range: $.validator.format( "Пожалуйста, введите число от {0} до {1}." ),
                    max: $.validator.format( "Пожалуйста, введите число, меньшее или равное {0}." ),
                    min: $.validator.format( "Пожалуйста, введите число, большее или равное {0}." )
                } );
                break;
            default:
        }

        $.validator.addMethod("email", function( value, element ) {
            return this.optional( element ) || /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value ); // /^[a-zA-Z\-\._]+@[a-z0-9\-]+(\.[a-z0-9\-]+)*\.[a-z]+$/i
        });

        $.validator.addMethod("uname", function(value, element) {
            return this.optional(element) || /^[a-z\u00C0-\u01FF\u0400-\u04FF'][ a-z\u00C0-\u01FF\u0400-\u04FF'\-]*[a-z\u00C0-\u01FF\u0400-\u04FF]$/i.test( value ); // european and cyrillic names
        });

        $.validator.addMethod("phone", function(value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7);
        });

        $.validator.addMethod("phonecomplete", function(value, element) {
            return this.optional(element) || (value.replace(/\D/, "").length >= 7 && $(element).inputmask('isComplete'));
        });

        $.validator.addMethod("maskcomplete", function(value, element) {
            return this.optional(element) || $(element).inputmask('isComplete');
        });

        $.validator.addMethod("password", function(value, element) {
            return this.optional(element) || value.length >= 6;
        });

        $.validator.addMethod("passwordconfirm", function(value, element) {
            var $password = $($(element).data("rule-passwordconfirm-password"));
            return this.optional(element) || !$password.length || !$password.val() || value === $password.val();
        });

        $.validator.addMethod("dateonly", function( value, element ) {
            return this.optional( element ) || /^\d{2}\.\d{2}\.\d{4}$/.test( value );
        });

        $.validator.addMethod("datetime", function( value, element ) {
            return this.optional( element ) || /^\d{2}\.\d{2}\.\d{4}\s+\d{2}\:\d{2}$/.test( value );
        });

        $.validator.addMethod("dateexist", function( value, element ) {
            var p = value.split(/\D+/);
            var valid = false;
            if (p.length > 2) {
                var d = new Date (p[2], p[1]-1, p[0], (p[3])?p[3]:0, (p[4])?p[4]:0, (p[5])?p[5]:0);
                valid = Object.prototype.toString.call(d) === "[object Date]" && !isNaN(d.getTime()) && d.getFullYear() == parseInt(p[2], 10) && d.getMonth() == parseInt(p[1], 10)-1 && d.getDate() == parseInt(p[0], 10);
            }
            return this.optional( element ) || valid;
        });

        $.validator.addMethod("creditcard_expires", function(value, element) {
            var value_arr = value.split(/\s+\/\s+/);
            var today = new Date();
            var valid = false;
            if (value_arr.length == 2) {
                var fullYear = parseInt(((value_arr[1].length == 2)?'20':'')+value_arr[1], 10);
                var expDate = new Date(fullYear, parseInt(value_arr[0], 10) - 1, today.getDate(), 23, 59, 59);
                var expDateTimestamp = expDate.getTime();
                if (isNaN(expDateTimestamp) == false) {
                    if (Object.prototype.toString.call(expDate) === "[object Date]") {
                        valid = !isNaN(expDate.getTime()) && expDate.getFullYear() == fullYear && expDate.getMonth() == value_arr[0] - 1 && expDate.getDate() == today.getDate();
                    }
                }
                if (valid && today.getTime() <= expDate.getTime()) {
                    valid = true;
                }
                else {
                    valid = false;
                }
            }
            return this.optional(element) || valid;
        });

        $.validator.addMethod("creditcard_cvc", function(value, element) {
            return this.optional(element) || value.replace(/\D/, "").length == 3;
        });

        $.each($.validator.methods, function (key, value) {
            $.validator.methods[key] = function () {
                if(arguments.length > 0) {
                    arguments[0] = $.trim(arguments[0]);
                }
                return value.apply(this, arguments);
            };
        });

        $(document).on("keyup blur change check-form", ".form-disabled-until-valid :input", function () {
            var $form = $(this).closest(".form-disabled-until-valid");
            var result = $form.validate().checkForm();
            var $if_filled = $form.find('.form-disabled-until-valid-check-filled');
            var is_filled = $if_filled.filter('.filled').length == $if_filled.length;
            if ($if_filled.length) {
                result = result && is_filled;
            }
            var $button = $form.find(".disabled-until-valid");
            $button.prop('disabled', !result);
        });

        $(document).on("change change-checkbox-valid", "input[type='checkbox'], input[type='radio']", function () {
            var $form = $(this).closest("form");
            var validator = $form.data("validator");
            if (validator) $(this).valid();
        });

        $("body").addClass("validate-inited");
    }

    var $forms = context?$('.form-validate', context):$('.form-validate');
    $forms = $forms.not(".form-validate-inited");
    $forms.each(function(){
        $(this).validate({
            onkeyup: false,
            normalizer: function( value ) {
                return $.trim( value );
            },
            errorClass: 'form__error',
            validClass: 'valid',
            errorElement: 'div',
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('error').removeClass('valid');
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).addClass('valid').removeClass('error');
            },
            errorPlacement: function(error, element) {
                $(element).closest(".form__item-field").append(error);
            },
            submitHandler: function(form, e) {
                $(form).trigger("submit.valid");
            }
        });

        $(this).addClass("form-validate-inited")
    });

    $(".form-disabled-until-valid-onload").find(":input").first().trigger("check-form");

}