/*
* SVG icon url update
* */

function icons_load() {
    var url = $('body').data('svg-sprite-url');
    $.ajax({
        url: url,
        localCache: true,
        cacheTTL: 1,
        dataType: 'text',
        cacheKey: 'svg-sprite'
    }).done(function (response) {
        $('body').append('<div id="svg-icons-container" style="height: 0; width: 0; position: absolute; top: -99999px; left: 0; visibility: hidden;">' + response + '</div>');
    });
}


/*

 Loaders (for buttons)

 */

function loader_init() {
    $btn = $(".loader-on-page-load");
    loader_add($btn);
    $btn.prop("disabled", true);
}

function loader_add($o) {
    var loader = '<span class="loader"><svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\
    width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;" xml:space="preserve">\
        <path fill="#fff" d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">\
        <animateTransform attributeType="xml"\
            attributeName="transform"\
            type="rotate"\
            from="0 25 25"\
            to="360 25 25"\
            dur="0.6s"\
            repeatCount="indefinite"/>\
        </path>\
        </svg></div>';
    if ($o.closest('.btn').length) {
        $o.wrapInner("<div class='btn__loader-inner'>");
    }
    $o.append(loader).addClass("loading");
    var $loader = $o.find("> .loader");
    $loader.addClass("active");
}

function loader_remove($o) {
    $o.find(".btn__loader-inner").children().unwrap();
    $o.removeClass("loading");
    var $loader = $o.find("> .loader");
    $loader.removeClass("active");
    $loader.remove();
}


/*

Gallery and Modal Popups

 */

function fancybox_init() {

    if (!$("html").hasClass("fancybox-inited")) {

        $("html").addClass("fancybox-inited");

        $.fancybox.defaults.lang = $('html').attr('lang');

        if ($().fancybox) {
            $.fancybox.options_default = {
                slideShow: false,
                hash: false,
                loop: true,
                idleTime: 10,
                margin: [44, 0],
                gutter: 50,
                keyboard: true,
                animationEffect: "zoom",
                arrows: true,
                infobar: true,
                toolbar: true,
                buttons: [
                    //'slideShow',
                    'fullScreen',
                    //'thumbs',
                    'close'
                ],
                smallBtn: true,
                btnTpl: {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                thumbs: {
                    autoStart: false,
                    hideOnClose: true
                }
            };

            $.fancybox.options_modal = {
                animationDuration: 700,
                parentEl: ".wrap",
                slideShow: false,
                hash: false,
                keyboard: false,
                ajax: {
                    settings: {
                        cache: false
                    }
                },
                hideScrollbar: true,
                baseClass: "fancybox-container--popup",
                trapFocus: false,
                autoFocus: false,
                touch: false,
                popup_default: true,
                btnTpl: {
                    smallBtn: '<div data-fancybox-close class="fancybox-close"><svg class="icon icon--cross"><use xlink:href="#icon-cross"></use></svg></div>'
                },
                afterLoad: function (instance, current) {
                    current.$content.wrap("<div>");
                    if (current.$content.hasClass('fancybox-content')) {
                        current.$content.removeClass('fancybox-content').parent().addClass('fancybox-content');
                    }
                    console.log(current.$content.parent().length);
                    bind_widgets(current.$content.parent());
                },
                afterShow: function (instance, current) {
                    if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
                        Blazy.revalidate();
                    }
                },
                beforeClose: function (instance, current) {
                    if (current.$content) {
                        current.$content.closest(".fancybox-container").addClass('fancybox-is-close');
                    }
                },
            };

            $.fancybox.defaults.afterLoad = function (instance, current) {
                current.$content.closest('.fancybox-container').addClass('fancybox-container--' + current.type);
            };
            $.fancybox.defaults.beforeLoad = function (instance, current) {
                if ($().tooltipster) {
                    var tooltips = $.tooltipster.instances();
                    $.each(tooltips, function (i, tooltip) {
                        tooltip.close();
                    });
                }
            };
            $.fancybox.defaults.hash = false;
            $.fancybox.defaults.errorTpl = '<div><div class="panel panel--compact"><div class="panel__content text-align-center"><p>The requested content cannot be loaded. <br/> Please try again later.</p></div></div></div>';
        }

        $('body').on('mousewheel', function (e) {
            if ($(".fancybox-is-zoomable").length) {
                e.preventDefault();
                var instance = $.fancybox.getInstance();
                if ($(".fancybox-is-zoomable").length && instance.canPan() && e.deltaY > 0) {
                    instance.scaleToFit();
                } else if ($(".fancybox-can-zoomIn").length && instance.isScaledDown() && e.deltaY < 0) {
                    instance.scaleToActual(e.clientX, e.clientY);
                }
            }
        });
    }

    if ($().fancybox) {

        var options = $.fancybox.options_default;
        $fancybox_links_all = $("[data-fancybox]").not(".fancybox-inited");

        $fancybox_links = $fancybox_links_all.not("[data-type='ajax'], [data-type='inline']");
        fancybox_links_by_group = [];
        groups = [];
        $fancybox_links.each(function () {
            var group = $(this).attr("data-fancybox");
            if (!group) group = "";
            if ($.inArray(group, groups) < 0) groups.push(group);
        });
        for (group in groups) {
            options_current = $.extend(true, {}, options);
            var $items = $fancybox_links.filter("[data-fancybox='" + groups[group] + "']");
            var $first = $items.eq(0);
            if (typeof $first.attr("data-fancybox-loop") !== "undefined") {
                options_current["loop"] = $first.data("fancybox-loop");
            }
            $items.fancybox(options_current).addClass("fancybox-inited");
        }

        $fancybox_links_ajax = $fancybox_links_all.filter("[data-type='ajax'], [data-type='inline']");
        $fancybox_links_ajax.each(function () {
            var options = $.fancybox.options_modal;
            if ($(this).data('ajax-type')) {
                options = $.extend({}, options, {
                    ajax: {
                        settings: {
                            type: $(this).data('ajax-type'),
                        }
                    }
                });
            }
            if ($(this).data('ajax-data')) {
                options = $.extend({}, options, {
                    ajax: {
                        settings: {
                            data: $(this).data('ajax-data'),
                        }
                    }
                });
            }
            if ($(this).data('popup-class')) {
                options = $.extend({}, options, {
                    baseClass: options.baseClass + ' ' + $(this).data('popup-class')
                });
            }
            $(this).fancybox(options);
        }).addClass("fancybox-inited");
    }
}



/*

Yandex Map UI

 */

function map_load() {
    if ($(".js-map").not('.js-map-on-scroll').length && !$("#api-maps-yandex").length)
    {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'api-maps-yandex';
        script.src = '//api-maps.yandex.ru/2.1/?lang=ru-RU&onload=map_init';
        if ($('body').data('ymaps-api-key')) {
            script.src += '&apikey='+$('body').data('ymaps-api-key');
        }
        document.body.appendChild(script);
    }
    else if ($("#api-maps-yandex").length) {
        map_init();
    }
}

function objGetBounds(obj) {
    var minP = [999, 999];
    var maxP = [0, 0];
    for (var i in obj) {
        minP[0] = Math.min(minP[0], obj[i].geometry.getCoordinates()[0]);
        minP[1] = Math.min(minP[1], obj[i].geometry.getCoordinates()[1]);

        maxP[0] = Math.max(maxP[0], obj[i].geometry.getCoordinates()[0]);
        maxP[1] = Math.max(maxP[1], obj[i].geometry.getCoordinates()[1]);
    }
    return [minP, maxP];
}

function map_init($o) {
    $o = $('.js-map').not('.js-map-on-scroll');
    $o.not('.js-map--inited').each(function () {
        var $map = $(this);
        var center = [0,0];
        if ($map.data("center")) {
            center = $map.data("center").split(",");
        }
        var zoom = $(this).data("zoom");
        var map = new ymaps.Map(this, {
            center: center,
            zoom: zoom,
            controls: (typeof $(this).data('controls') !== 'undefined')?$(this).data('controls'):[]
        });
        map.behaviors.disable("scrollZoom");

        map.controls.remove('searchControl');
        map.controls.remove('typeSelector');
        map.controls.remove('fullscreenControl');
        map.controls.add('zoomControl', {
            float: 'none',
            position: {
                right: '1rem',
                top: '4rem'
            },
            size: 'small'
        });
        map.controls.add('geolocationControl', {
            //noPlacemark: true,
            float: 'none',
            position: {
                right: '1rem',
                top: '9rem'
            }
        });
        var geolocationControl = new ymaps.control.GeolocationControl({
            options: {
                noPlacemark: true,
                float: 'none',
                position: {
                    right: '1rem',
                    top: '9rem'
                }
            }
        });
        geolocationControl.events.add('locationchange', function (event) {
            var position = event.get('position');
            map.panTo(position);
        });
        map.controls.add(geolocationControl);


        $map.data("map", map);

        var resp_width = 340/(24/parseInt($('body').css('font-size'), 10));

        // Создание макета балуна на основе Twitter Bootstrap.
        customBalloonLayout = ymaps.templateLayoutFactory.createClass(
            '<div class="map-popover js-map-popover">' +
            '<a class="map-popover__close js-map-popover-close" href="#"><svg class="icon"><use xlink:href="' + $("body").data("svg-sprite-url") + '#icon-cross"></use></svg></a>' +
            '<div class="map-popover__arrow js-map-popover-arrow"></div>' +
            '<div class="map-popover__marker-size js-map-popover-marker-size"></div>' +
            '<div class="map-popover__inner">' +
            '$[[options.contentLayout observeSize minWidth='+resp_width+' maxWidth='+resp_width+' maxHeight='+resp_width+']]' +
            '</div>' +
            '</div>', {
            /**
             * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
             * @function
             * @name build
             */
            build: function () {
                this.constructor.superclass.build.call(this);

                this._$element = $('.js-map-popover', this.getParentElement());

                this.applyElementOffset();

                this._$element.find('.js-map-popover-close')
                    .on('click', $.proxy(this.onCloseClick, this));
            },

            /**
             * Удаляет содержимое макета из DOM.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
             * @function
             * @name clear
             */
            clear: function () {
                this._$element.find('.js-map-popover-close')
                    .off('click');

                this.constructor.superclass.clear.call(this);
            },

            /**
             * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
             * @function
             * @name onSublayoutSizeChange
             */
            onSublayoutSizeChange: function () {
                customBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

                if(!this._isElement(this._$element)) {
                    return;
                }

                this.applyElementOffset();

                this.events.fire('shapechange');
            },

            /**
             * Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
             * @function
             * @name applyElementOffset
             */
            applyElementOffset: function () {
                this._$element.css({
                    left: -(this._$element[0].offsetWidth / 2),
                    top: -(this._$element[0].offsetHeight + this._$element.find('.js-map-popover-arrow')[0].offsetHeight + this._$element.find('.js-map-popover-marker-size')[0].offsetHeight)
                });
            },

            /**
             * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
             * @function
             * @name onCloseClick
             */
            onCloseClick: function (e) {
                e.preventDefault();

                this.events.fire('userclose');
            },

            /**
             * Используется для автопозиционирования (balloonAutoPan).
             * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
             * @function
             * @name getClientBounds
             * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
             */
            getShape: function () {
                if(!this._isElement(this._$element)) {
                    return customBalloonLayout.superclass.getShape.call(this);
                }

                var position = this._$element.position();

                return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                    [position.left, position.top], [
                        position.left + this._$element[0].offsetWidth,
                        position.top + this._$element[0].offsetHeight + this._$element.find('.js-map-popover-arrow')[0].offsetHeight
                    ]
                ]));
            },

            /**
             * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
             * @function
             * @private
             * @name _isElement
             * @param {jQuery} [element] Элемент.
             * @returns {Boolean} Флаг наличия.
             */
            _isElement: function (element) {
                return element && element[0] && element.find('.js-map-popover-arrow')[0];
            }
        });

        // Создание вложенного макета содержимого балуна.
        customBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
            '<h3 class="map-popover__header">$[properties.balloonHeader]</h3>' +
            '<div class="map-popover__content">$[properties.balloonContent]</div>'
        );

        map.geoObjects_pl = [];
        map_geoObjects_update($map, map);

        map.events.add('click', function (e) {
            e.get('target').balloon.close();
        });

        $map.addClass("js-map--inited").trigger('map-init');
    });
}

function map_geoObjects_update($map, map) {

    if (typeof map === 'undefined') {
        map = $map.data('map');
    }

    if ($map.data("point") && point) {
        var placemark = map_build_placemark(map, point, $map);
        map.geoObjects.add(placemark);
    }

    $map.find(".js-map-point").each(function (i) {
        var $this = $(this);
        $(this).data("index", i);
        if ($(this).data("point")) {
            var point = $(this).data("point").split(",");
            for(var j = 0; j < point.length; j++) {
                point[j] = parseFloat(point[j]);
            }
            var placemark = map_build_placemark(map, point, $(this));
            map.geoObjects_pl.push(placemark);
        }
    });

    if ($map.data("clusterer") && map.geoObjects_pl.length > 1) {
        var clusterer_options = {
            preset: 'islands#yellowClusterIcons',
            clusterDisableClickZoom: false,
            zoomMargin: 50,
            duration: 500
        };
        if ($map.data("clusterer-custom")) {
            var clusterIcons = [
                {
                    size: [31, 41],
                    offset: [-15, -41]
                }];
            var clusterIconLayout = ymaps.templateLayoutFactory.createClass(
                '<div class="map-marker map-marker--cluster"><div class="map-marker__text">$[properties.geoObjects.length]</div></div>');
            clusterer_options = $.extend({}, clusterer_options, {
                clusterIcons: clusterIcons,
                clusterIconContentLayout: clusterIconLayout,
            });
        }
        var clusterer = new ymaps.Clusterer(clusterer_options);
        clusterer.events
            .add(['mouseenter', 'mouseleave'], function (e) {
                var target = e.get('target'),
                    type = e.get('type');
                if (typeof target.getGeoObjects != 'undefined') {
                    // Событие произошло на кластере.
                    if (type == 'mouseenter') {
                        target.options.set('preset', 'islands#orangeClusterIcons');
                    } else {
                        target.options.set('preset', 'islands#yellowClusterIcons');
                    }
                }
            });
        clusterer.options.set({
            gridSize: 128,
            openBalloonOnClick: false
        });
        clusterer.add(map.geoObjects_pl);
        map.geoObjects.add(clusterer);
        $map.data("clusterer", clusterer);
    }
    else {
        $.each(map.geoObjects_pl, function (k, v) {
            map.geoObjects.add(v);
        });
    }

    if ($map.data("auto-bounds") && map.geoObjects_pl.length > 1) {
        map.setBounds(map.geoObjects.getBounds(), {checkZoomRange: true, margin: 20}).then(function () {
            if (map.getZoom() > $map.data("zoom")) map.setZoom($map.data("zoom"));
            if ($map.data("set-initial-params")) {
                $map.attr("data-center", map.getCenter().join(","));
                $map.attr("data-zoom", map.getZoom());
            }
            if ($map.data("set-initial-params-by-first")) {
                $map.attr("data-center", map.geoObjects.get(0).geometry.getCoordinates());
                map.setCenter(map.geoObjects.get(0).geometry.getCoordinates());
            }
        });
    }
    else {
        if ($map.data("set-initial-params") || $map.data("set-initial-params-by-first")) {
            $map.attr("data-center", map.geoObjects.get(0).geometry.getCoordinates());
            map.setCenter(map.geoObjects.get(0).geometry.getCoordinates());
        }
    }
}

function map_on_scroll_init($o, context) {

    if (!$("html").hasClass("js-map-on-scroll-inited")) {

        if (!("IntersectionObserver" in window)) {
            $(window).on('resize scroll', function (e) {
                map_on_scroll_load_depricated();
            });
        }

        $("html").addClass("js-map-on-scroll-inited");
    }

    if (typeof $o === 'undefined' || !$o) {
        $o = context?$('.js-map-on-scroll', context):$('.js-map-on-scroll');
    }
    if ("IntersectionObserver" in window) {
        var imageObserver = new IntersectionObserver(function (entries, observer) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    var item = entry.target;
                    map_on_scroll_load_change($(item));
                    imageObserver.unobserve(item);
                }
            });
        });

        $o.not(".js-map-on-scroll-observer-inited").each(function () {
            imageObserver.observe(this);
            $(this).addClass('js-map-on-scroll-observer-inited');
        });
    }
    else {
        map_on_scroll_load_depricated($o, context);
    }
}

function map_on_scroll_load_depricated($o, context) {
    if (typeof $o === 'undefined' || !$o) {
        $o = context ? $('.js-map-on-scroll', context) : $('.js-map-on-scroll');
    }
    $o.not(".js-map-on-scroll-loaded").each(function () {
        if ($(this).offset().top - $(window).height() / 3 < $(window).scrollTop() + $(window).height()) {
            map_on_scroll_load_change($(this));
        }
    });
}

function map_on_scroll_load_change($o, callback) {
    $o.removeClass('js-map-on-scroll');
    map_load($o);
    $o.addClass("js-map-on-scroll-loaded");
    $o.trigger('js-map-on-scroll-loading');
}

function map_build_placemark(map, point, $this) {
    var markerLayout = {
        hideIconOnBalloonOpen: false,
        balloonShadow: false,
        balloonPanelMaxMapArea: 0,
        zIndexActive: 7000,
        pane: 'balloon'
    };
    markerLayout["balloonLayout"] = customBalloonLayout;
    markerLayout["balloonContentLayout"] = customBalloonContentLayout;
    var iconLayout = ymaps.templateLayoutFactory.createClass("<" + (($this.data("link")) ? "a href='" + $this.data("link") + "'" : "div") + (($this.data("data-id")) ? " data-id='" + $this.data("data-id") + "'" : "") + " class='marker-pin "+$this.data("html-class")+"'>" + (($this.data("marker-text")) ? "<div class='map-marker__text'>" + $this.data("marker-text") + "</div>" : "") + "</" + (($this.data("link")) ? "a" : "div") + ">");//<svg class='icon'><use xlink:href='" + $('body').data('svg-sprite-url') + "#icon-color-marker-pin'></use></svg>
    markerLayout["iconLayout"] = iconLayout;
    var placemark = new ymaps.Placemark(point, {}, markerLayout);
    if ($this.data("cursor")) {
        markerLayout["cursor"] = $this.data("cursor");
    }
    if ($this.data("ajax-url")) {
            placemark.options.set('openBalloonOnClick', false);
            placemark.options.set('openEmptyBalloon', true);
            // var $o = $('<div class="text-align-center"></div>');
            // loader_add($o);
            // placemark.properties.set('balloonContent', $('<div>').append($o.clone()).html());
            placemark.options.set('ajaxUrl', $this.data("ajax-url"));
            placemark.events.add('click', function (e) {
                var placemark = e.get('target');
                placemark.options.set('balloonAutoPan', $(window).width() >= 768);
                if (placemark.balloon.isOpen()) {
                    placemark.balloon.close();
                }
                else {
                    if ($this.data("ajax-only-once") && placemark.properties.get('isBalloonContentLoaded')) {
                        placemark_ajax_delay(function(){
                            placemark.balloon.open();
                        }, 0);
                    }
                    else {
                        // var $o = $('<div class="text-align-center"></div>');
                        // loader_add($o);
                        // placemark.properties.set('balloonContent', $('<div>').append($o.clone()).html());
                        // placemark_ajax_delay(function(){
                        //     placemark.balloon.open();
                        // }, 100);
                        var url = e.get("target").options.get('ajaxUrl');
                        if (url) {
                            $.ajax({
                                url: url,
                                cache: (typeof $this.data("ajax-url") !== 'undefined')?$this.data("ajax-url"):false,
                                beforeSend: function () {
                                },
                                success: function (response) {
                                    placemark_ajax_delay(function(){
                                        placemark.properties.set('balloonContent', response);
                                        placemark.properties.set('isBalloonContentLoaded', true);
                                        placemark.balloon.open();
                                    }, 0);
                                },
                                error: function (response) {
                                    placemark_ajax_delay(function(){
                                        placemark.properties.set('balloonContent', 'Ошибка загрузки данных.');
                                    }, 0);
                                }
                            });
                        }
                    }
                }
            });
    }
    else {
        var html = $.trim($this.html());
        if (html) {
            placemark.properties.set('balloonContent', html);
            //placemark.balloon.open();
        }
    }

    return placemark;
}

var placemark_ajax_delay = (function () {
    var timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();