
$(function () {
    var isMac = navigator.platform.toUpperCase().indexOf('MAC')>=0;
    var isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    var isIOS = /(iPhone|iPod|iPad)/i.test(navigator.platform);
    if (isIOS) {
        $('html').addClass('ios');
    }
    if (isMacLike) {
        $('html').addClass('mac-like');
    }
    if (isMac) {
        $('html').addClass('mac');
    }
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();

    responsive_init();

    $("body").addClass("ready").removeClass("no-transition");

    init_event_handlers();
    bind_widgets();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").removeClass("not-loaded").addClass("loaded");

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    scroll_animations();
});

$(window).on("resize", function (e) {
    responsive_update(null, true);
    scroll_animations();
});

function init_event_handlers() {
    icons_load();
    blazy_init();
    goto_init();
    scroll_animations();
    fix_touch_hovers();
    click_touch_init();
    header_init();
    form_process_init();
    toggle_element_init();
    expand_it_init();
    upload_init();
    misc_init();
}

function bind_widgets(context) {
    img_to_bg(null, context);
    validate_init();
    fancybox_init();
    checkbox_plain_init(null, context);
    ddbox_init(context);
    mask_init(context);
    map_on_scroll_init(null, context);
    countdown_init();
}